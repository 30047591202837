import React from "react";
import RootRouter from "./router";
import "./styles/globals/index.scss";

function App() {
  return (
    <div className="App">
      <RootRouter />
    </div>
  );
}

export default App;
